import React from 'react'

export default () => (
    <div
        style={{
            display: 'inline-block',
            width: '1rem',
            height: '1rem',
            position: 'relative'
        }}
    >
        <svg
            style={{
                position: 'absolute',
                top: '3px',
                left: '-1px'
            }}
            viewBox='0 0 24 24'
        >
            <path
                fill='#000000'
                d='M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z'
            />
        </svg>{' '}
    </div>
)
