import React, { Fragment } from 'react'
import Layout from '../layout'
import Arrow from '../components/Arrow'
import { Link } from 'gatsby'
import { language } from '../buildConfig.js'
import { menu, privacy } from '../messages'

export default () => (
    <Layout language={language} page='applePrivacy'>
        <section>
            <div className='text-content'>
                <h1 className='h2'>{privacy[language].appleTitle}</h1>
                <ol className='body-4 top-level'>
                    {(language === 'fr' && (
                        <Fragment>
                            <li>
                                <div>
                                    Pour activer le verrouillage de l’écran,
                                    accédez à Paramètres <Arrow /> Général{' '}
                                    <Arrow /> Accessibilité. Activez les
                                    fonctionnalités Accès guidé et Raccourci
                                    d’accessibilité.
                                </div>
                            </li>
                            <li>
                                Accédez aux Réglages du code d’accès pour
                                configurer votre code d’accès et activer ou
                                désactiver la fonctionnalité Touch ID. (Dans le
                                iPhone X, vous pouvez activer ou désactiver Face
                                ID.)
                            </li>
                            <li>
                                <div>
                                    <strong>Verrouillage d’écran</strong> <br />
                                    Suivez les instructions à l’écran pour
                                    utiliser l’Accès guidé et verrouiller votre
                                    écran en tout temps.
                                </div>
                            </li>
                        </Fragment>
                    )) || (
                        <Fragment>
                            <li>
                                <div>
                                    To enable screen locking, go to Settings{' '}
                                    <Arrow /> General <Arrow /> Accessibility.
                                    Turn on Guided Access and Accessibility
                                    Shortcut.
                                </div>
                            </li>
                            <li>
                                Go to Passcode Settings to set your passcode and
                                enable/disable Touch ID. (For iPhone X, you may
                                enable/disable Face ID).
                            </li>
                            <li>
                                <div>
                                    <strong>Lock Screen</strong> <br />
                                    Follow the instructions on your screen to
                                    use Guided Access and lock your screen at
                                    any time.
                                </div>
                            </li>
                        </Fragment>
                    )}
                </ol>
                <Link to={`/#${menu[language].privacy.id}`}>
                    {privacy[language].back}
                </Link>
            </div>
        </section>
    </Layout>
)
